import { cn } from "@ag/design-system/utils";

export interface ExtendedMarker extends google.maps.Marker {
  customFlagColour?: "yellow" | "red";
}

export const FlagCountBadge = ({
  variant,
  countValue,
}: {
  variant: "yellow" | "red";
  countValue: number;
}) => {
  return (
    <div
      className={cn(
        "rounded-full px-3 py-[3px] text-h6",
        variant === "red"
          ? "bg-messaging-error-100 text-messaging-error-900"
          : "bg-messaging-warning-100 text-messaging-warning-900",
      )}
    >
      {countValue}
    </div>
  );
};
