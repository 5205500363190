import { GoogleMap } from "@react-google-maps/api";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import {
  useMultiPolygonBoundariesStore,
  usePolygonBoundariesStore,
} from "@ag/carbon/components";
import { Icon } from "@ag/design-system/assets";
import { Button } from "@ag/design-system/atoms";
import { colorsObject } from "@ag/design-system/tokens";
import {
  calculateMultipolygonCentroid,
  calculatePolygonCentroid,
  centerMap,
  getGeoJSONCoordinatesFromPolygonPaths,
} from "@ag/map/helpers";
import { GeoJSONGeometry, GeometryType, LatLng } from "@ag/map/types";

import BackButton from "~components/BackButton";
import {
  BoundaryAppearanceToggles,
  DEFAULT_ZOOM,
  ExitEditModeModal,
  BoundaryManagementMapField as Field,
  FieldInfoPanel,
  FieldLabel,
  FieldListPanel,
  FlagMarker,
  FlagMarkerClusterer,
  MAP_OPTIONS,
  useNearbyFieldBoundariesQuery,
  useSaveEditedFieldBoundary,
} from "~features/boundary-verification";
import { FlagCountBadge } from "~features/boundary-verification/components/flag-count-badge";
import {
  CheckResults,
  FieldBoundariesWithChecksResults,
  FieldWithAllBoundaries,
  IssueSeverityFlagTypes,
} from "~features/boundary-verification/entities/field-with-all-boundaries";
import { BoundaryMapField } from "~features/boundary-verification/types/boundary-map-field";
import { AuthorizedSidebar } from "~features/navigation";
import ListLayout from "~layouts/list-layout";

const BoundaryManagementMap = () => {
  const { farmId } = useParams();

  const { data: regionFields, isLoading: isRegionFieldsLoading } =
    useNearbyFieldBoundariesQuery(
      farmId
        ? { farmId }
        : {
            // TODO: remove hard coded lat lon once search is implemented
            lonlat: { lat: 47.5790839, lng: 30.0268952 },
          },
    );

  const [activeBoundaries, setActiveBoundaries] = useState<BoundaryMapField[]>(
    [],
  );

  const [totalRedIssues, setTotalRedIssues] = useState<number | undefined>(
    undefined,
  );
  const [totalYellowIssues, setTotalYellowIssues] = useState<
    number | undefined
  >(undefined);

  const saveEditedFieldBoundary = useSaveEditedFieldBoundary();

  const [currentlySelectedField, setCurrentlySelectedField] =
    useState<BoundaryMapField | null>();

  const [isFieldListVisible, setIsFieldListVisibile] = useState(false);

  const [isMRVBoundaryVisible, setIsMRVBoundaryVisible] = useState(false);
  const [isFarmerBoundaryVisible, setIsFarmerBoundaryVisible] = useState(false);
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const [isExitEditModeModalOpen, setIsExitEditModeModalOpen] = useState(false);
  const [exitEditModeAction, setExitEditModeAction] = useState<
    "fieldList" | "back" | null
  >(null);

  const mapRef = useRef<google.maps.Map | null>(null);
  const [currentZoom, setCurrentZoom] = useState<number>();

  const [activeBoundaryDisplayColor, setActiveBoundaryDisplayColor] = useState<
    Record<number, string>
  >(colorsObject.data.blue);
  const [isActiveBoundaryOpacityOn, setIsActiveBoundaryOpacityOn] =
    useState(true);

  const {
    outerPath: polygonOuterPath,
    innerPaths: polygonInnerPaths,
    setOuterPath: setPolygonOuterPath,
    setInnerPaths: setPolygonInnerPaths,
    setMapInstance: setMapPolygonInstance,
    setEditAction,
  } = usePolygonBoundariesStore();

  const {
    outerPath: multiPolygonOuterPath,
    innerPaths: multiPolygonInnerPaths,
    setOuterPath: setMultiPolygonOuterPath,
    setInnerPaths: setMultiPolygonInnerPaths,
    setMapInstance: setMapMultiPolygonInstance,
  } = useMultiPolygonBoundariesStore();

  useEffect(() => {
    setActiveBoundaries([]);

    let totalRedIssues = 0;
    let totalYellowIssues = 0;

    const calculateIssueCounts = (
      checkResults: CheckResults,
    ): {
      redOverlapCount: number;
      yellowOverlapCount: number;
      redIouCount: number;
      yellowIouCount: number;
    } => {
      const redOverlapCount = checkResults.overlap.filter(
        result => result.flag === IssueSeverityFlagTypes.RED,
      ).length;
      const yellowOverlapCount = checkResults.overlap.filter(
        result => result.flag === IssueSeverityFlagTypes.YELLOW,
      ).length;
      const redIouCount =
        checkResults.iou && checkResults.iou.flag === IssueSeverityFlagTypes.RED
          ? 1
          : 0;
      const yellowIouCount =
        checkResults.iou &&
        checkResults.iou.flag === IssueSeverityFlagTypes.YELLOW
          ? 1
          : 0;

      totalRedIssues += redOverlapCount + redIouCount;
      totalYellowIssues += yellowOverlapCount + yellowIouCount;

      return {
        redOverlapCount,
        yellowOverlapCount,
        redIouCount,
        yellowIouCount,
      };
    };

    const isBoundaryValid = (
      boundary: FieldBoundariesWithChecksResults,
    ): boolean => {
      return !!(
        boundary &&
        boundary.boundaries &&
        (boundary.boundaries.type === GeometryType.Polygon ||
          boundary.boundaries.type === GeometryType.MultiPolygon ||
          boundary.boundaries.type === GeometryType.Point) &&
        boundary.isActive
      );
    };

    const determineIssueSeverityFlag = (
      checkResults: CheckResults,
    ): "red" | "yellow" | undefined => {
      if (
        checkResults.overlap.some(
          result => result.flag === IssueSeverityFlagTypes.RED,
        ) ||
        (checkResults.iou &&
          checkResults.iou.flag !== IssueSeverityFlagTypes.NONE &&
          checkResults.iou.flag === IssueSeverityFlagTypes.RED)
      ) {
        return "red";
      }
      if (
        checkResults.overlap.some(
          result => result.flag === IssueSeverityFlagTypes.YELLOW,
        ) ||
        (checkResults.iou &&
          checkResults.iou.flag === IssueSeverityFlagTypes.YELLOW)
      ) {
        return "yellow";
      }
      return undefined;
    };

    regionFields?.forEach((field: FieldWithAllBoundaries) => {
      field.boundaries.forEach((boundary: FieldBoundariesWithChecksResults) => {
        if (isBoundaryValid(boundary)) {
          const {
            redOverlapCount,
            yellowOverlapCount,
            redIouCount,
            yellowIouCount,
          } = calculateIssueCounts(
            boundary.checkResults || { overlap: [], iou: undefined },
          );

          setActiveBoundaries((prev: BoundaryMapField[]) => [
            ...prev,
            {
              id: field.carbonFieldId,
              geometry: boundary.boundaries as GeoJSONGeometry,
              properties: {},
              type: boundary.boundaries?.type as GeometryType,
              bbox: boundary.boundaries?.bbox as [
                number,
                number,
                number,
                number,
              ],
              issueSeverityFlag: determineIssueSeverityFlag(
                boundary.checkResults || { overlap: [], iou: undefined },
              ),
              editable: field.editable,
              checkResults: boundary.checkResults,
              totalIssues:
                redOverlapCount +
                yellowOverlapCount +
                redIouCount +
                yellowIouCount,
            } as BoundaryMapField,
          ]);
        }
      });
    });

    setTotalRedIssues(totalRedIssues);
    setTotalYellowIssues(totalYellowIssues);
  }, [regionFields, isRegionFieldsLoading]);

  const handleMapLoaded = useCallback(
    (map: google.maps.Map) => {
      centerMap(map, activeBoundaries);
      mapRef.current = map;
    },
    [activeBoundaries],
  );

  const handleViewReset = () => {
    setCurrentlySelectedField(null);
    mapRef.current && centerMap(mapRef.current, activeBoundaries);
  };

  const handleFieldClicked = useCallback(
    (field: BoundaryMapField): void => {
      if (isEditModeOn || currentlySelectedField?.id == field.id) {
        return;
      }

      setIsFieldListVisibile(false);

      setCurrentlySelectedField(field);
      mapRef.current && field.geometry && centerMap(mapRef.current, [field]);

      mapRef.current &&
        field.geometry?.type === GeometryType.Polygon &&
        setMapPolygonInstance(mapRef.current);

      mapRef.current &&
        field.geometry?.type === GeometryType.MultiPolygon &&
        setMapMultiPolygonInstance(mapRef.current);
    },
    [
      isEditModeOn,
      mapRef,
      currentlySelectedField?.id,
      setCurrentlySelectedField,
      setMapPolygonInstance,
      setMapMultiPolygonInstance,
    ],
  );

  const handleBackButtonClicked = () => {
    if (isEditModeOn) {
      setExitEditModeAction("back");
      setIsExitEditModeModalOpen(true);
      return;
    }
    handleViewReset();
  };

  const handleFieldListButtonClicked = () => {
    if (isEditModeOn) {
      setExitEditModeAction("fieldList");
      setIsExitEditModeModalOpen(true);
      return;
    }
    if (currentlySelectedField) {
      handleViewReset();
    }
    setIsFieldListVisibile(true);
  };

  const handleExitEditMode = () => {
    if (exitEditModeAction === "fieldList") {
      handleViewReset();
      setIsFieldListVisibile(true);
    } else if (exitEditModeAction === "back") {
      handleViewReset();
    }
    setIsExitEditModeModalOpen(false);
    setIsEditModeOn(false);
  };

  const handleSetMrvAsActive = (mrvBoundary: GeoJSONGeometry) => {
    if (mrvBoundary.type === GeometryType.Polygon) {
      const outerPath = mrvBoundary.coordinates[0].map(
        coords => new google.maps.LatLng(coords[1], coords[0]),
      );
      const innerPaths = mrvBoundary.coordinates
        .slice(1)
        .map(inner =>
          inner.map(coords => new google.maps.LatLng(coords[1], coords[0])),
        );

      setPolygonOuterPath(outerPath);
      setPolygonInnerPaths(innerPaths);
    } else if (mrvBoundary.type === GeometryType.MultiPolygon) {
      const outerPaths = mrvBoundary.coordinates.map(polygon =>
        polygon[0].map(coords => new google.maps.LatLng(coords[1], coords[0])),
      );
      const innerPaths = mrvBoundary.coordinates.map(polygon =>
        polygon
          .slice(1)
          .map(inner =>
            inner.map(coords => new google.maps.LatLng(coords[1], coords[0])),
          ),
      );

      setMultiPolygonOuterPath(outerPaths);
      setMultiPolygonInnerPaths(innerPaths);
    }
  };

  const handleSaveEditedBoundary = () => {
    const saveBoundary = (
      type: GeometryType,
      coordinates: number[][][] | number[][][][],
    ) => {
      setEditAction("edit");
      saveEditedFieldBoundary.mutate(
        {
          carbonFieldId: currentlySelectedField!.id,
          boundaries: { type, coordinates },
        },
        {
          onSuccess: () => {
            setIsEditModeOn(false);
          },
        },
      );
    };

    if (currentlySelectedField?.geometry?.type === GeometryType.Polygon) {
      const polygonCoordinates = getGeoJSONCoordinatesFromPolygonPaths(
        polygonOuterPath,
        polygonInnerPaths,
      );
      saveBoundary(GeometryType.Polygon, polygonCoordinates);
    } else if (
      currentlySelectedField?.geometry?.type === GeometryType.MultiPolygon
    ) {
      if (!multiPolygonOuterPath) return;

      const multiPolygonCoordinates = multiPolygonOuterPath.map((path, index) =>
        getGeoJSONCoordinatesFromPolygonPaths(
          path,
          multiPolygonInnerPaths[index],
        ),
      );

      saveBoundary(GeometryType.MultiPolygon, multiPolygonCoordinates);
    }
  };

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <BackButton />
        <ListLayout.TopBarTitle>Field boundaries</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <div className="flex h-full w-full flex-col">
          <div className="flex w-full items-center justify-between bg-grey-100 px-6 py-3">
            {/* TODO: use actual farm info */}
            <p className="text-h2">Farm name | Farm ID</p>

            <div className="flex items-center gap-2">
              {((totalRedIssues != undefined && totalRedIssues > 0) ||
                (totalYellowIssues != undefined && totalYellowIssues > 0)) && (
                <Icon
                  name="flag"
                  fontSize={14}
                  color={colorsObject.grey[900]}
                />
              )}

              {totalRedIssues != undefined && totalRedIssues > 0 && (
                <FlagCountBadge variant={"red"} countValue={totalRedIssues} />
              )}

              {totalYellowIssues != undefined && totalYellowIssues > 0 && (
                <FlagCountBadge
                  variant={"yellow"}
                  countValue={totalYellowIssues}
                />
              )}

              <Button
                icon="list-ul"
                variant="secondary"
                onClick={() => handleFieldListButtonClicked()}
              >
                See field list
              </Button>
            </div>
          </div>

          <div className="flex h-[calc(100%-64px)] w-full bg-grey-100 px-3 py-1">
            {currentlySelectedField && (
              <div className="w-[360px]">
                <FieldInfoPanel
                  selectedField={currentlySelectedField}
                  checkResults={
                    activeBoundaries.find(
                      field => field.id === currentlySelectedField.id,
                    )?.checkResults
                  }
                  isEditModeOn={isEditModeOn}
                  isLoading={
                    isRegionFieldsLoading || saveEditedFieldBoundary.isLoading
                  }
                  onFarmerBoundariesVisibilityChange={
                    setIsFarmerBoundaryVisible
                  }
                  onMRVBoundariesVisibilityChange={setIsMRVBoundaryVisible}
                  onBoundaryEdit={() => setIsEditModeOn(true)}
                  onEditDiscard={() => setIsEditModeOn(false)}
                  onSetMrvAsActive={handleSetMrvAsActive}
                  onBoundarySave={handleSaveEditedBoundary}
                  onMapCentre={() =>
                    mapRef.current &&
                    centerMap(mapRef.current, [currentlySelectedField])
                  }
                  onBackClick={handleBackButtonClicked}
                />
              </div>
            )}

            {isFieldListVisible && (
              <div className="flex h-full bg-grey-100 pb-3 pl-3">
                <FieldListPanel
                  fields={activeBoundaries}
                  onClose={() => setIsFieldListVisibile(false)}
                  onFieldCardClick={handleFieldClicked}
                />
              </div>
            )}

            {!isRegionFieldsLoading && activeBoundaries.length > 0 && (
              <div className="flex h-full w-full bg-grey-100 px-3 pb-3">
                <GoogleMap
                  mapContainerClassName="h-full w-full rounded-lg boundary-verification-map"
                  options={MAP_OPTIONS}
                  zoom={DEFAULT_ZOOM}
                  onZoomChanged={() =>
                    setCurrentZoom(mapRef.current?.getZoom())
                  }
                  onLoad={handleMapLoaded}
                >
                  <BoundaryAppearanceToggles
                    activeBoundaryDisplayColor={activeBoundaryDisplayColor}
                    onActiveBoundaryDisplayColorChange={
                      setActiveBoundaryDisplayColor
                    }
                    isActiveBoundaryOpacityOn={isActiveBoundaryOpacityOn}
                    onActiveBoundaryOpacityChange={setIsActiveBoundaryOpacityOn}
                  />

                  {activeBoundaries &&
                    activeBoundaries.map(field => (
                      <Field
                        key={`active-${field.id}`}
                        field={field}
                        activeBoundaryDisplayColor={activeBoundaryDisplayColor}
                        isActiveBoundaryOpacityOn={isActiveBoundaryOpacityOn}
                        isFarmerBoundaryVisible={isFarmerBoundaryVisible}
                        isMrvBoundaryVisible={isMRVBoundaryVisible}
                        isSelected={currentlySelectedField?.id === field.id}
                        isInEditMode={isEditModeOn}
                        onClick={handleFieldClicked}
                      />
                    ))}

                  <FlagMarkerClusterer>
                    {clusterer => {
                      const fieldsWithIssue = activeBoundaries.filter(
                        field =>
                          field.checkResults &&
                          (field.checkResults.overlap.length > 0 ||
                            (field.checkResults.iou &&
                              field.checkResults.iou?.flag !==
                                IssueSeverityFlagTypes.NONE)) &&
                          field.geometry &&
                          (field.geometry?.type === GeometryType.Polygon ||
                            field.geometry.type ===
                              GeometryType.MultiPolygon) &&
                          field.geometry.coordinates,
                      );

                      return (
                        <>
                          {activeBoundaries &&
                            fieldsWithIssue &&
                            fieldsWithIssue.map(field => {
                              const position =
                                field.geometry?.type === GeometryType.Polygon
                                  ? calculatePolygonCentroid(
                                      field.geometry?.coordinates as [
                                        number,
                                        number,
                                      ][][],
                                    )
                                  : calculateMultipolygonCentroid(
                                      field.geometry?.coordinates as [
                                        [number, number][][],
                                      ],
                                    );

                              return (
                                <FlagMarker
                                  key={`field-marker-${field.id}`}
                                  fieldId={field.id}
                                  position={position}
                                  flagColour={
                                    field.issueSeverityFlag as "yellow" | "red"
                                  }
                                  clusterer={clusterer}
                                  onClick={() =>
                                    handleFieldClicked(
                                      field as BoundaryMapField,
                                    )
                                  }
                                />
                              );
                            })}
                        </>
                      );
                    }}
                  </FlagMarkerClusterer>

                  {currentlySelectedField &&
                    currentlySelectedField.geometry &&
                    currentZoom &&
                    currentZoom > 13 && (
                      <>
                        {(() => {
                          const fieldsOverlappingWithCurrentlySelectedField =
                            activeBoundaries.filter(field => {
                              return currentlySelectedField?.checkResults?.overlap?.some(
                                overlapCheckResult =>
                                  overlapCheckResult.overlappingFieldId ===
                                  field.id,
                              );
                            });
                          const position =
                            currentlySelectedField.geometry.type ===
                            GeometryType.Point
                              ? ({
                                  lat: currentlySelectedField.geometry
                                    .coordinates[1],
                                  lng: currentlySelectedField.geometry
                                    .coordinates[0],
                                } as LatLng)
                              : currentlySelectedField.geometry.type ===
                                  GeometryType.Polygon
                                ? calculatePolygonCentroid(
                                    currentlySelectedField.geometry
                                      .coordinates as [number, number][][],
                                  )
                                : calculateMultipolygonCentroid(
                                    currentlySelectedField.geometry
                                      .coordinates as [[number, number][][]],
                                  );

                          return (
                            <>
                              {/* Show fieldId label on the selected field */}
                              <FieldLabel
                                fieldLabel={currentlySelectedField.id}
                                position={position}
                                isSelectedField={true}
                                isCentrePointOnly={
                                  currentlySelectedField.geometry!.type ===
                                  GeometryType.Point
                                }
                              />
                              {/* Show fieldId label on all the fields involved in an overlap with the selected field */}
                              {fieldsOverlappingWithCurrentlySelectedField.map(
                                overlapField => {
                                  const overlappingFieldPosition =
                                    overlapField.geometry!.type ===
                                    GeometryType.Point
                                      ? ({
                                          lat: overlapField.geometry!
                                            .coordinates[1],
                                          lng: overlapField.geometry!
                                            .coordinates[0],
                                        } as LatLng)
                                      : overlapField.geometry!.type ===
                                          GeometryType.Polygon
                                        ? calculatePolygonCentroid(
                                            overlapField.geometry!
                                              .coordinates as [
                                              number,
                                              number,
                                            ][][],
                                          )
                                        : calculateMultipolygonCentroid(
                                            overlapField.geometry!
                                              .coordinates as [
                                              [number, number][][],
                                            ],
                                          );
                                  return (
                                    <FieldLabel
                                      key={`field-label-${overlapField.id}`}
                                      fieldLabel={overlapField.id}
                                      position={overlappingFieldPosition}
                                      isSelectedField={false}
                                      isCentrePointOnly={
                                        overlapField.geometry!.type ===
                                        GeometryType.Point
                                      }
                                    />
                                  );
                                },
                              )}
                            </>
                          );
                        })()}
                      </>
                    )}
                </GoogleMap>
              </div>
            )}
          </div>
        </div>
      </ListLayout.Content>

      <ExitEditModeModal
        isOpen={isExitEditModeModalOpen}
        onClose={() => setIsExitEditModeModalOpen(false)}
        onExitClick={handleExitEditMode}
      />
    </ListLayout.Root>
  );
};

export default BoundaryManagementMap;
