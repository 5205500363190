import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onExitClick: () => void;
};

export const ExitEditModeModal = ({ isOpen, onClose, onExitClick }: Props) => {
  return (
    <Modal.Root
      isOpen={isOpen}
      onRequestClose={onClose}
      size="small"
      isCentered
    >
      <Modal.Header>
        <Modal.Title>Are you sure you want to exit?</Modal.Title>
      </Modal.Header>
      <Modal.Content>
        <div>
          <p className="text-p2">Your changes will not be saved</p>
          <div className="flex w-full justify-end gap-6">
            <Button variant="text" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onExitClick}>
              Yes, exit
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal.Root>
  );
};
