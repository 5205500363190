import { Icon } from "@ag/design-system/assets";
import { colorsObject } from "@ag/design-system/tokens";
import { cn } from "@ag/design-system/utils";

import {
  IOUCheckResult,
  IssueSeverityFlagTypes,
  OverlapCheckResult,
} from "../entities/field-with-all-boundaries";

export const CheckResultInfoCard = ({
  overlapCheckResult,
  IOUCheckResult,
}: {
  overlapCheckResult?: OverlapCheckResult;
  IOUCheckResult?: IOUCheckResult;
}) => {
  const bgColorClass =
    overlapCheckResult?.flag === IssueSeverityFlagTypes.RED ||
    IOUCheckResult?.flag === IssueSeverityFlagTypes.RED
      ? "bg-messaging-error-100"
      : "bg-messaging-warning-100";
  const textColorClass =
    overlapCheckResult?.flag === IssueSeverityFlagTypes.RED ||
    IOUCheckResult?.flag === IssueSeverityFlagTypes.RED
      ? "text-messaging-error-900"
      : "text-messaging-warning-900";
  const iconBgColorClass =
    overlapCheckResult?.flag === IssueSeverityFlagTypes.RED ||
    IOUCheckResult?.flag === IssueSeverityFlagTypes.RED
      ? "bg-messaging-error-300"
      : "bg-messaging-warning-300";
  const iconColor =
    overlapCheckResult?.flag === IssueSeverityFlagTypes.RED ||
    IOUCheckResult?.flag === IssueSeverityFlagTypes.RED
      ? colorsObject.messaging.error[900]
      : colorsObject.messaging.error[900];

  return (
    <div
      className={cn(
        "flex flex-row items-center gap-3 rounded-lg p-4",
        bgColorClass,
      )}
    >
      <div
        className={cn(
          "flex items-center justify-center rounded-full p-2",
          iconBgColorClass,
        )}
      >
        <Icon name="flag" color={iconColor} fontSize={11} />
      </div>

      {overlapCheckResult && (
        <div className="flex flex-col gap-1">
          <p className={cn("text-h5", textColorClass)}>Overlapping field</p>
          <p className={cn("text-p2", textColorClass)}>
            Active boundary has {(overlapCheckResult.overlap * 100).toFixed(2)}%
            overlap with field {overlapCheckResult.overlappingFieldId}
          </p>
        </div>
      )}

      {IOUCheckResult &&
        IOUCheckResult.flag === IssueSeverityFlagTypes.YELLOW && (
          <div className="flex flex-col gap-1">
            <p className={cn("text-h5", textColorClass)}>
              Detected boundary mismatch
            </p>
            <p className={cn("text-p2", textColorClass)}>
              MRV detected boundary has {IOUCheckResult.result.iou.toFixed(2)}%
              IOU against active boundary
            </p>
          </div>
        )}

      {IOUCheckResult && IOUCheckResult.flag === IssueSeverityFlagTypes.RED && (
        <div className="flex flex-col gap-1">
          <p className={cn("text-h5", textColorClass)}>Overissuance risk</p>
          <p className={cn("text-p2", textColorClass)}>
            Active boundary is {IOUCheckResult.result.sizeRatio.toFixed(2)}%
            larger than MRV detected boundary
          </p>
        </div>
      )}
    </div>
  );
};
