import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config/react-query";

import { generateUsersQueryKey } from "./get-users";

const deleteUser = (userId: string) => {
  return axios.delete(`/api/v1/carbon-users/${userId}`, {
    params: {
      deleteAuthUser: true,
    },
    apiSource: "node-carbon",
  });
};

export const useDeleteUserMutation = () =>
  useMutation(deleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateUsersQueryKey());
    },
    onError: error => {
      ToastNotification.error(error);
    },
  });
